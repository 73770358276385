(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
$(document).ready(function() {
    $('.js-link-to-swipe').click(function() {
        const elementClick = $(this).attr('href');
        const destination = $(elementClick).offset().top;
        $('html:not(:animated),body:not(:animated)').animate({
            scrollTop: destination
        }, 800);
        return false;
    });
    $('.icon-menu').click(function () {
        if ($(this).hasClass('icon-close')) {
            $('.nav').removeClass('nav_active');
            $('.icon-open').css('display', 'block');
            $('.icon-close').css('display', 'none');
        } else {
            $('.nav').addClass('nav_active');
            $('.icon-open').css('display', 'none');
            $('.icon-close').css('display', 'block');
        }
    });
    $('.nav__link').click(function () {
        if ($('.nav-link').hasClass('nav-active')) {
            $('.nav').removeClass('nav_active');
            $('.icon-open').css('display', 'block');
            $('.icon-close').css('display', 'none');
        }
    });
});

},{}]},{},[1])